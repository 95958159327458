import { cssJoin } from '@volvo-cars/css/utils';
import { IconButton } from '@volvo-cars/react-icons';
import React, { useEffect, useState } from 'react';

import { tocBarHeight, zIndex } from '@collab/style/customThemeTokens';
import { environment } from '@collab/utils/environment';
import { useScrollToId } from '@collab/utils/scrollToId';
import isSafari from '@vcc-www/utils/isSafari';

import { useTocStyles } from '../../MenuStyleProvider';
import { useActiveItem, useHeadingRefs } from '../../hooks';
import { PageTopicItem } from '../../types';
import useScrollSection from '../../utils/useScrollSection';
import MobileBackdrop from '../MobileBackdrop';
import mobileMenuStyles from '../MobileMenu.module.css';
import { CategoryLabel, PageTopicLabel } from '../MobileMenuItemLabel';
import {
  useMobileMenuActions,
  useMobileMenuContainer,
  useMobileMenuToC,
} from '../hooks';
import styles from './TableOfContent.module.css';
import useShowToc from './useShowToc';

const TableOfContentBar = () => {
  const activeItem = useActiveItem();

  if (!activeItem) {
    return null;
  }

  return (
    <Bar
      pageId={activeItem.id}
      pageLabel={activeItem.label}
      tocItems={activeItem.items}
    />
  );
};

const Bar: React.FC<{
  pageId: string;
  pageLabel: string;
  tocItems: PageTopicItem[];
}> = ({ pageId, pageLabel, tocItems }) => {
  const [activeTopic, setActiveTopic] = useState<PageTopicItem | undefined>(
    tocItems[0],
  );
  const { setToCOpen } = useMobileMenuActions();
  const isToCOpen = useMobileMenuToC();
  const isMobileMenuOpen = useMobileMenuContainer();
  const tocStyles = useTocStyles();

  const show = useShowToc();
  const refs = useHeadingRefs();
  const scrollSection = useScrollSection(refs, tocStyles.scrollOffset);

  const setActiveByIndex = (index: number) => setActiveTopic(tocItems[index]);

  useEffect(() => {
    if (!activeTopic && !environment.IS_PROD) {
      console.warn('Current section does not have a PageTopic');
    }
  }, [activeTopic]);

  useEffect(() => {
    setActiveByIndex(0);
    setToCOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  useEffect(() => {
    // Lock scroll to Topic when backdrop is open.
    const hasOpenBackdrop = isToCOpen || isMobileMenuOpen;

    if (!hasOpenBackdrop) {
      setActiveByIndex(scrollSection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollSection]);

  const onPageLabelClick = () => {
    if (isSafari()) {
      setActiveByIndex(0);
    }
    setToCOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div
        className={styles.tocContainer}
        style={{
          paddingTop: tocStyles.topOffset,
          top: show ? 0 : -tocStyles.topOffset,
          zIndex: zIndex.mobileToc,
        }}
        aria-label="Table of content navigation"
      >
        <div className={styles.tocBar} style={{ height: tocBarHeight }}>
          <div className={styles.tocBreadCrumb}>
            <span className={styles.tocBreadCrumbTextWrap}>
              <button type="button" onClick={onPageLabelClick}>
                {pageLabel}
              </button>
              {activeTopic && (
                <>
                  <p style={{ padding: '0 0.5rem', alignSelf: 'center' }}>/</p>
                  <TopicLabel href={activeTopic.href}>
                    {activeTopic.label}
                  </TopicLabel>
                </>
              )}
            </span>
          </div>
          <IconButton
            iconName={
              isToCOpen ? 'navigation-chevronup' : 'navigation-chevrondown'
            }
            variant="clear"
            onClick={() => setToCOpen(!isToCOpen)}
            aria-label="Toggle table of content"
            aria-expanded={isToCOpen}
            className="self-center"
          />
        </div>
        <ToC
          items={tocItems}
          open={isToCOpen}
          activeId={activeTopic?.id}
          onTopicClick={setActiveByIndex}
        />
      </div>
      <MobileBackdrop
        show={isToCOpen}
        onClose={() => setToCOpen(false)}
        zIndex={zIndex.mobileTocBackdrop}
      />
    </>
  );
};

const TopicLabel: React.FC<{
  href: string;
  children: React.ReactNode;
}> = ({ href, children }) => {
  const { setToCOpen } = useMobileMenuActions();
  const scrollToId = useScrollToId();

  const onClick = async () => {
    const currentUrlHash = href.split('#')[1];

    setToCOpen(false);
    await scrollToId(currentUrlHash, true);
  };

  return (
    <button
      className={cssJoin(styles.ellipsis, 'font-medium')}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const ToC: React.FC<{
  items: PageTopicItem[];
  open: boolean;
  activeId: string | undefined;
  onTopicClick: (index: number) => void;
}> = ({ items, open, activeId, onTopicClick }) => {
  const { setToCOpen } = useMobileMenuActions();
  const scrollToId = useScrollToId();
  const tocStyles = useTocStyles();

  return (
    <nav
      aria-hidden={!open}
      className={styles.tocWrapper}
      style={{
        height: open
          ? `calc(100vh - ${tocBarHeight + tocStyles.topOffset}px)`
          : 0,
      }}
    >
      <div
        className={cssJoin(styles.tocSubMenu, mobileMenuStyles.thinScrollbar)}
      >
        <div
          className={styles.tocSubMenuGradient}
          style={{
            opacity: open ? 1 : 0,
            transitionDelay: open ? '0ms' : '500ms',
          }}
        />

        <div className={styles.tocSubMenuInner}>
          <div className={styles.tocSubMenuHeading}>
            <CategoryLabel label="Contents" />
          </div>
          {items.map(({ id, href, label }, index) => {
            const onClick = async () => {
              const urlHash = href.split('#')[1];

              if (isSafari()) {
                onTopicClick(index);
              }

              setToCOpen(false);
              await scrollToId(urlHash, true);
            };

            return (
              <PageTopicLabel
                key={id}
                onClick={onClick}
                active={id === activeId}
                label={label}
              />
            );
          })}
        </div>
      </div>
    </nav>
  );
};

export default TableOfContentBar;
