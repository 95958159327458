import { cssJoin } from '@volvo-cars/css/utils';
import { Icon, IconType } from '@volvo-cars/react-icons';
import React from 'react';

import { Link } from '@collab/atoms/index';
import { useScrollToId } from '@collab/utils/scrollToId';

import Lock from '../icons/Lock';
import styles from './Menu.module.css';

type ChevronDirection = 'down' | 'forward';

type LabelBaseProps = {
  active: boolean;
  label: string;
};

type PageLabelProps = LabelBaseProps & {
  href: string;
  open: boolean;
  isRootItem: boolean;
  isProtected: boolean;
  onClick: () => void;
};
type SectionLabelProps = LabelBaseProps & {
  open: boolean;
  isRootItem: boolean;
  isProtected: boolean;
  href?: string;
  onClick: () => void;
};

type ModuleLabelProps = LabelBaseProps & {
  open: boolean;
  isRootItem: boolean;
  onClick: () => void;
};

type PageTopicLabelProps = LabelBaseProps & {
  urlHash: string;
};

export const PageLabel: React.FC<PageLabelProps> = ({
  active,
  href,
  isProtected,
  isRootItem,
  label,
  onClick,
  open,
}) => {
  return active || isProtected ? (
    <button
      aria-expanded={open}
      className={labelButtonClasses(active, isRootItem)}
      onClick={onClick}
      type="button"
    >
      <Label active={active}>{label}</Label>
      {isProtected && <LockIcon />}
    </button>
  ) : (
    <Link
      aria-expanded={open}
      className={labelButtonClasses(active, isRootItem)}
      href={href}
    >
      <Label active={active}>{label}</Label>
    </Link>
  );
};

export const SectionLabel: React.FC<SectionLabelProps> = ({
  active,
  href,
  isProtected,
  isRootItem,
  label,
  onClick,
  open,
}) => {
  const shouldNavigate = href && !active && !isProtected;

  const content = (
    <>
      <Label active={active}>{label}</Label>
      {isProtected ? (
        <LockIcon />
      ) : (
        <ChevronButton
          direction="down"
          open={open}
          ariaLabel={`Toggle ${label} sub-menu`}
          onClick={shouldNavigate ? onClick : undefined}
        />
      )}
    </>
  );

  return shouldNavigate ? (
    <Link
      aria-expanded={open}
      className={labelButtonClasses(active, isRootItem)}
      href={href}
    >
      {content}
    </Link>
  ) : (
    <button
      aria-expanded={open}
      className={labelButtonClasses(active, isRootItem)}
      onClick={onClick}
      type="button"
    >
      {content}
    </button>
  );
};

export const ModuleLabel: React.FC<ModuleLabelProps> = ({
  active,
  isRootItem,
  label,
  onClick,
  open,
}) => (
  <button
    aria-expanded={open}
    className={labelButtonClasses(active, isRootItem)}
    onClick={onClick}
    type="button"
  >
    <Label active={active}>{label}</Label>
    <ChevronButton
      direction="forward"
      open={open}
      ariaLabel={`Toggle ${label} sub-menu`}
    />
  </button>
);

export const CloseModuleLabel: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <>
    <button
      type="button"
      onClick={onClick}
      className={cssJoin(
        'font-medium',
        styles.menuItemBase,
        styles.menuItemCloseModule,
      )}
    >
      <Icon type="navigation-chevronback-16" color="secondary" />
      Back
    </button>
    <div className={styles.topFade} />
  </>
);

export const CategoryLabel: React.FC<{
  label: string;
}> = ({ label }) => (
  <div className={cssJoin(styles.menuItemBase, styles.categoryLabel)}>
    <small className="micro text-secondary">{label}</small>
  </div>
);

export const PageTopicLabel: React.FC<PageTopicLabelProps> = ({
  urlHash,
  active,
  label,
}) => {
  const scrollToId = useScrollToId();

  return (
    <button
      className={styles.menuItemBase}
      onClick={() => scrollToId(urlHash)}
      type="button"
    >
      <Label active={active}>{label}</Label>
    </button>
  );
};

const ChevronButton: React.FC<{
  ariaLabel: string;
  direction: ChevronDirection | undefined;
  onClick?: () => void;
  open: boolean | undefined;
}> = ({ open, direction = 'down', ariaLabel, onClick }) => {
  const iconType = `navigation-chevron${direction}-16` as IconType;

  const onChevronClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  if (onClick) {
    return (
      <button
        aria-expanded={open}
        aria-label={ariaLabel}
        className={cssJoin(
          styles.focusVisibleOutline,
          styles.chevron,
          styles.chevronHighlight,
        )}
        onClick={onChevronClick}
        type="button"
      >
        <Icon className="button-expanded:rotate-180" type={iconType} />
      </button>
    );
  }

  return (
    <div className={styles.chevron}>
      <Icon className="button-expanded:rotate-180" type={iconType} />
    </div>
  );
};

const Label: React.FC<{ active: boolean; children: string }> = ({
  active,
  children,
}) => (
  <p className={cssJoin(styles.menuItemText, active ? 'font-medium' : '')}>
    {children}
  </p>
);

const labelButtonClasses = (active: boolean, isRootItem: boolean) =>
  cssJoin(styles.menuItemBase, active && isRootItem && styles.filledLabel);

const LockIcon = () => (
  <div className={styles.lock}>
    <Lock />
  </div>
);
